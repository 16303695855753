import React from "react";
import ConnectButton from "./Buttons/ConnectButton";
import Logo from "../assets/logo.svg";

function Header({
  state: { walletConnected, walletAddress, walletAlgos },
  setState,
}) {
  return (
    <div className="header">
      <img src={Logo} alt="logo" />
      <div>
        {/* {walletAlgos && (
          <img style={{ width: "25px" }} src={AlgoLogo} alt="zone-logo" />
        )}
        <span style={{ color: "white", marginRight: "10px" }}>
          {walletAlgos}
        </span> */}
        <ConnectButton
          text={walletAddress ? walletAddress : "Connect"}
          onClick={() => setState({ showWalletChooseModal: true })}
          disabled={walletConnected}
        />
      </div>
    </div>
  );
}

export default Header;
