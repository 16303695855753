import React, { useState } from "react";
import { claimStatus, shortenString } from "../../utils";
import Circle from "../../assets/circle.svg";
import AlgoLogo from "../../assets/algorandlogo.svg";
import { Button } from "react-bootstrap";

export default function AccountInfo({
  state: { walletAddress, walletAlgos, allocations, eligibleForTokens },
}) {
  const [showFutureAllocations, setShowFutureAllocations] = useState(false);

  if (!walletAddress) {
    return null;
  }
  const claimedTokens = // test claimed tokens if allocation changes it should also change
    allocations !== undefined
      ? allocations.reduce((total, a) => {
          if (a.isDelivered) return a.amount + total;
          return total;
        }, 0)
      : 0;

  return (
    <div className="middleContainer__accountInfo">
      <div className="accountInfo__address">
        <img src={Circle} alt="circle" />
        <p>{shortenString(walletAddress, 10, 10)}</p>
      </div>
      <div className="accountInfo__rewardsBalance">
        <p>Account Info</p>
        <hr />
        <div className="whiteBold">
          <p>Claimed Tokens</p>
          <p>{claimedTokens}</p>
        </div>
        <div className="whiteBold">
          <p>Algorand Balance</p>
          <p>
            <img style={{ width: "25px" }} src={AlgoLogo} alt="zone-logo" />
            {walletAlgos}
          </p>
        </div>
        <hr />
        <div className="whiteBold">
          Future Allocations
          <Button
            variant="outline-secondary"
            size="sm"
            disabled={!eligibleForTokens}
            onClick={() => setShowFutureAllocations(!showFutureAllocations)}
          >
            {!eligibleForTokens
              ? "Unavailable"
              : showFutureAllocations
              ? "Hide"
              : "Show"}
          </Button>
        </div>
        <hr />
        {allocations &&
          showFutureAllocations &&
          allocations
            .filter((a) => a.claimStatus === claimStatus.FUTURE_ELIGIBLE)
            .map((a) => {
              return (
                <div key={a._id}>
                  <p>{new Date(a.deliveryAt).toISOString().split("T")[0]}</p>
                  <p>{Math.round(a.amount * 100) / 100}</p>
                </div>
              );
            })}
      </div>
    </div>
  );
}
