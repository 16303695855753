import algosdk from "algosdk";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { apiUpdateAllocation, apiUpdateInvestor } from "../../api";
import Logo from "../../assets/zobo.svg";
import env from "../../env";
import { createOptinTransaction, AlgorandClient } from "../../services/algod";
import { claimStatus, walletType } from "../../utils";
import ClaimButton from "../Buttons/ClaimButton";
import CloseButton from "../Buttons/CloseButton";
import OptinButton from "../Buttons/OptinButton";

export default function ClaimToken({
  walletAddress,
  walletConnected,
  setState,
  tokenAmount,
  eligibleForTokens,
  isOptedIn,
  optinFailed,
  allocations,
  claimRequested,
  peraWallet,
  myAlgoConnect,
}) {
  const [loading, setLoading] = useState(false);

  const updateAllocations = async () => {
    for (let allocation of allocations) {
      if (
        allocation.claimStatus === claimStatus.ELIGIBLE &&
        !allocation.isDelivered
      ) {
        await apiUpdateAllocation(allocation._id, {
          claimStatus: claimStatus.REQUESTED,
        });
      }
    }
  };

  useEffect(() => {
    const setOptin = async () => {
      setLoading(true);

      const response = await axios.get(`v2/accounts/${walletAddress}`, {
        baseURL: env.ALGOD_URL,
      });
      const assets = response.data.assets;
      assets.forEach((asset) => {
        if (asset["asset-id"] === env.TOKEN_ID) {
          setState({ isOptedIn: true });
          return;
        }
      });
      // Old Code
      // const isOptedIn = await account.tokenAccepted(env.TOKEN_ID);
      // // await new Promise((r) => setTimeout(r, 50000));
      // setState({ isOptedIn });
      setLoading(false);
    };
    if (isOptedIn === undefined && walletAddress) setOptin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletAddress]);

  const tokenOptinCallback = async () => {
    const txn = await createOptinTransaction({
      walletAddress,
      assetId: env.TOKEN_ID,
    });

    const singleTxnGroups = [{ txn: txn, signers: [walletAddress] }];
    let walletName = localStorage.getItem("walletType");
    if (walletName === walletType.myalgo) {
      try {
        const signedTxn = await myAlgoConnect.signTransaction(txn.toByte());
        const txnResult = await AlgorandClient.sendRawTransaction(
          signedTxn.blob
        ).do();

        await algosdk.waitForConfirmation(AlgorandClient, txnResult.txId, 5);
        setState({ isOptedIn: true });
      } catch (error) {
        console.log("Couldn't sign asset transfer txns", error);
      }
    } else {
      try {
        const signedTxn = await peraWallet.signTransaction([singleTxnGroups]);
        const txnResult = await AlgorandClient.sendRawTransaction(
          signedTxn
        ).do();

        await algosdk.waitForConfirmation(AlgorandClient, txnResult.txId, 5);
        setState({ isOptedIn: true });
      } catch (error) {
        console.log("Couldn't sign Opt-in txns", error);
      }
    }
  };

  const claimButtonCallback = async () => {
    try {
      // await contractOptIn(account);
      await apiUpdateInvestor(walletAddress, {
        contractOptin: true,
        zoneOptin: true, // again updating it
      });
      await updateAllocations();
      setState({ claimRequested: true });
    } catch (err) {
      console.log(err);
      if (err.message.search("PopupOpenError") !== -1)
        return toast.error("Can not open popup window");
      toast.error(err.message || err);
    }
  };

  return (
    <div className="middleContainer__claimToken">
      <h3>Claim your ZONE</h3>
      {eligibleForTokens === undefined ? (
        <p>
          Please make sure you are connected with the right wallet to claim
          ZONE.
        </p>
      ) : eligibleForTokens ? (
        <h6 style={{ color: "#76DD73", marginBottom: "20px" }}>
          {tokenAmount === 0
            ? "Thank you for Claiming ZONE, your claim was successful."
            : "Congratulation You are eligible to claim ZONE!!"}
        </h6>
      ) : (
        <>
          <h5 style={{ color: " #FF6161" }}>
            This wallet is not eligible to claim ZONE.
          </h5>
          <CloseButton
            onClick={() =>
              window.localStorage.clear() & window.location.reload()
            }
          />
        </>
      )}
      {eligibleForTokens !== false && (
        <>
          <div className="tokenCountContainer">
            {isOptedIn && claimRequested ? (
              <p style={{ color: "white" }}>
                Thanks for requesting your claim, Your claim request is under
                process. Your tokens will be transferred soon.
              </p>
            ) : (
              <>
                <p>You will receive...</p>
                <div className="d-flex align-items-center justify-content-sm-between flex-wrap">
                  <div className="d-flex align-items-center">
                    <img
                      style={{
                        width: "35px",
                        opacity: tokenAmount !== undefined ? "1" : "0.5",
                      }}
                      src={Logo}
                      alt="zone-logo"
                    />
                    <h5
                      className="whiteBold mb-0"
                      style={{ marginLeft: "10px" }}
                    >
                      {tokenAmount !== undefined && tokenAmount}
                    </h5>
                  </div>
                  {walletConnected && (
                    <React.Fragment>
                      {!isOptedIn ? (
                        <OptinButton
                          onClick={tokenOptinCallback}
                          text={
                            loading
                              ? "Loading..."
                              : optinFailed
                              ? "Opt-in failed"
                              : "Opt-in"
                          }
                          disabled={loading}
                        />
                      ) : (
                        !!tokenAmount && (
                          <ClaimButton onClick={claimButtonCallback} />
                        )
                      )}
                    </React.Fragment>
                  )}
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}
