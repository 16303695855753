import React from "react";

export default function RewardsInfo() {
  return (
    <div className="middleContainer__rewardsInfo">
      <h1>Claims</h1>
      <h1>Portal</h1>
      {/* <p>
        Claim your ZONE token reward. Collect Airdrop and many more benifits
        with zone token.
</p> */}
    </div>
  );
}
