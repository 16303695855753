import React, { useEffect, useState } from "react";
import Header from "./Header";
import MiddleContainer from "./MiddleContainer/MiddleContainer";
import "./styles/LandingPage.css";
import WalletChooseModal from "./WalletChooseModal";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiGetAllocations } from "../api";
import { claimStatus } from "../utils";
import { LandingModal } from "./LandingModal";
import { PeraWalletConnect } from "@perawallet/connect";
import MyAlgoConnect from "@randlabs/myalgo-connect";

const peraWallet = new PeraWalletConnect();
const myAlgoConnect = new MyAlgoConnect({ disableLedgerNano: false });

function LandingPage() {
  const DEFAULT_STATE = {
    account: undefined,
    walletConnected: false,
    walletAddress: undefined,
    walletAlgos: undefined,
    optinFailed: false,
    isOptedIn: undefined,
    showWalletChooseModal: false,
    tokenAmount: undefined,
    eligibleForTokens: undefined, // will set it to boolean after fetching
    allocations: undefined,
    claimRequested: undefined,
  };

  const savedState = localStorage.getItem("state");
  const [state, _setState] = useState(
    savedState ? { ...JSON.parse(savedState) } : { ...DEFAULT_STATE },
  );
  const [showLandingModal, setShowLandingModal] = useState(true);

  const setState = (ob) => {
    _setState((pre) => ({
      ...pre,
      ...ob,
    }));
  };

  // this use effect fetches tokens from backend
  useEffect(() => {
    const setTokens = async () => {
      const data = await apiGetAllocations(state.walletAddress);
      setState({ allocations: data });

      if (data.length === 0) return setState({ eligibleForTokens: false });
      setState({ eligibleForTokens: true });

      const tokenAmount = data.reduce((total, allocation) => {
        if (
          allocation.claimStatus === claimStatus.ELIGIBLE &&
          !allocation.isDelivered
        )
          return total + allocation.amount;
        return total;
      }, 0);

      if (tokenAmount === 0) {
        if (
          data.filter((a) => a.claimStatus === claimStatus.REQUESTED).length !==
          0
        ) {
          setState({ claimRequested: true });
        } else {
          setState({ claimRequested: false });
        }
      }

      setState({ tokenAmount: Math.round(tokenAmount * 100) / 100 });
    };
    if (state.walletAddress) setTokens();
  }, [state.walletAddress]);

  return (
    <div className="landingPage">
      <Header state={state} setState={setState} />
      <MiddleContainer
        state={state}
        setState={setState}
        peraWallet={peraWallet}
        myAlgoConnect={myAlgoConnect}
      />
      <WalletChooseModal
        show={state.showWalletChooseModal}
        onHide={() => setState({ showWalletChooseModal: false })}
        state={state}
        setState={setState}
        peraWallet={peraWallet}
        myAlgoConnect={myAlgoConnect}
      />
      <LandingModal
        show={showLandingModal}
        onHide={() => setShowLandingModal(false)}
      />
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        pauseOnHover
        theme="dark"
      />
    </div>
  );
}

export default LandingPage;
