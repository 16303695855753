import React from "react";
import AccountInfo from "./AccountInfo";
import ClaimToken from "./ClaimToken";
import RewardsInfo from "./RewardsInfo";

function MiddleContainer({ state, setState, peraWallet, myAlgoConnect }) {
  return (
    <div className="middleContainer">
      {state.walletConnected ? <AccountInfo state={state} /> : <RewardsInfo />}
      <ClaimToken
        {...state}
        setState={setState}
        peraWallet={peraWallet}
        myAlgoConnect={myAlgoConnect}
      />
    </div>
  );
}

export default MiddleContainer;
