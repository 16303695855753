import React from "react";
import Button from "./Button";

export default function ClaimButton({ ...props }) {
  return (
    <Button className="claimButton" variant="outline-success" {...props}>
      Request Claim
    </Button>
  );
}
