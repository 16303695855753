import React from "react";
import "../styles/Buttons.css";
import Button from "./Button";

function ConnectButton({ text, onClick, ...props }) {
  return (
    <Button
      {...props}
      className="connectButton"
      variant="dark"
      onClick={onClick}
    >
      {text.length > 12
        ? `${text.slice(0, 8)}...${text.slice(text.length - 4)}`
        : text}
    </Button>
  );
}

export default ConnectButton;
