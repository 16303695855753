import algosdk from "algosdk";
import env from "../env";

export const AlgorandClient = new algosdk.Algodv2("", env.ALGOD_URL, "");

export const getAccountInformation = async (address) => {
  const info = await AlgorandClient.accountInformation(address).do();
  return info;
};

export const createOptinTransaction = async ({ assetId, walletAddress }) => {
  let params = await AlgorandClient.getTransactionParams().do();

  let opttxn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
    from: walletAddress,
    to: walletAddress,
    assetIndex: assetId,
    amount: 0,
    note: new Uint8Array("Zone token opt-in transaction"),
    suggestedParams: params,
  });
  return opttxn;
};
