export const shortenString = (string, start = 4, end = 4) => {
  if (string.lenght <= start + end) {
    return string;
  }
  return `${string.slice(0, start)}...${string.slice(string.length - end)}`;
};

export const walletType = {
  myalgo: "my-algo",
  connectWallet: "connect-wallet",
};

export const assert = (condition, message) => {
  if (!condition) {
    throw message || "Assertion failed";
  }
};

export const claimStatus = {
  REQUESTED: "REQUESTED",
  ELIGIBLE: "ELIGIBLE",
  FUTURE_ELIGIBLE: "ELIGIBLE_FOR_FUTURE",
  TRANSFERRED: "TRANSFERRED",
};
