const env = {
  BACKEND_URL: process.env.REACT_APP_BACKEND_URL,
  CONTRACT_ID: Number(process.env.REACT_APP_CONTRACT_ID),
  TOKEN_ID: Number(process.env.REACT_APP_TOKEN_ID),
  RETRY_COUNT: Number(process.env.REACT_APP_RETRY_COUNT),
  PROVIDER_ENV: process.env.REACT_APP_PROVIDER_ENV,
  REACH_CONNECTOR_MODE: process.env.REACT_APP_REACH_CONNECTOR_MODE,
  REACH_DEBUG: process.env.REACT_APP_REACH_DEBUG,
  ALGOD_URL: process.env.REACT_APP_ALGOD_URL,
};

export default env;
