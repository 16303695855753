import React, { useState } from "react";
import { Button as BootStrapButton } from "react-bootstrap";

export default function Button({ onClick, children, ...props }) {
  const [loading, setLoading] = useState(false);
  return (
    <BootStrapButton
      {...props}
      onClick={async () => {
        // not using disabled
        if (loading) {
          return;
        }
        setLoading(true);
        await Promise.resolve(onClick());
        setLoading(false);
      }}
    >
      {loading ? "Loading.." : children}
    </BootStrapButton>
  );
}
