import styled from "styled-components";
import { Modal } from "react-bootstrap";

export const ModalWrapper = styled(Modal)`
  font-family: "Poppins", sans-serif !important;
  & .landing--modal--bg {
    font-family: "Poppins", sans-serif !important;
    background-color: #1f2333 !important;
    background-position: top;
    margin: 0 auto;
    max-width: 698px !important;
    border: none !important;
    border-radius: 20px !important;
    -webkit-border-radius: 20px !important;
    -moz-border-radius: 20px !important;
    -ms-border-radius: 20px !important;
    -o-border-radius: 20px !important;
    overflow: hidden;
  }
  & .modal-header {
    font-family: "Poppins", sans-serif !important;
    border: none !important;
    & .btn-close {
      width: 40px;
      height: 40px;
      padding: 0px;
      background-color: #fff;
      border-radius: 10px;
      border: none;
      color: #1f2333;
      opacity: 1;
      position: absolute;
      z-index: 4;
      top: 16px;
      right: 16px;
    }
  }
  & .modal-body {
    font-family: "Poppins", sans-serif !important;
    padding: 3rem !important;
    padding-top: 0rem !important;
    margin-top: 7rem !important;
    @media screen and (max-width: 540px) {
      margin-top: 40px !important;
      padding: 1rem !important;
    }
  }
`;

export const BackgroundImageContainer = styled.div`
  position: absolute;
`;

export const ModalTitle = styled.div`
  font-family: "Poppins", sans-serif !important;
  color: white;
  font-size: 2rem;
  font-weight: 600;
  @media screen and (max-width: 991px) {
    max-width: 300px;
  }
`;

export const ModalInfo = styled.div`
  margin-top: 10px;
  margin-bottom: 30px;
  color: #ffffff;
  font-family: Poppins;
  font-family: "Poppins", sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.01em;
  text-align: left;
  max-width: 403px;
  & a {
    color: #ffffffb2 !important;
    text-decoration: underline !important;
  }
  @media screen and (max-width: 991px) {
    max-width: 300px;
  }
`;

export const ModalLinks = styled.div`
  & a {
    margin-right: 28px;
    background: linear-gradient(92.97deg, #ffdc5e 0.13%, #fcc601 99.87%);
    font-family: "Poppins", sans-serif !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #1f2333 !important;
    cursor: pointer;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
`;

export const ModalHashtag = styled.div`
  margin-top: 30px;
  font-family: "Poppins", sans-serif !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #ffffffb2;
`;
