import React from "react";
import Button from "./Button";

export default function OptinButton({ onClick, text, ...props }) {
  return (
    <Button
      onClick={onClick}
      variant="filled"
      className="optinButton"
      {...props}
    >
      {text}
    </Button>
  );
}
