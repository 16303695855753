import React from "react";
import Button from "./Button";

export default function CloseButton({ onClick }) {
  return (
    <Button
      onClick={onClick}
      size="lg"
      variant="outlined"
      className="whiteBold closeButton"
    >
      Close
    </Button>
  );
}
