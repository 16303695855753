import { Modal } from "react-bootstrap";
import {
  BackgroundImageContainer,
  ModalHashtag,
  ModalInfo,
  ModalTitle,
  ModalWrapper,
} from "./styles/LandingModal.styles";
import bgImg from "../assets/landingmodal.png";

export const LandingModal = (props) => {
  return (
    <ModalWrapper
      {...props}
      size="lg"
      centered
      contentClassName="landing--modal--bg"
    >
      <BackgroundImageContainer>
        <img src={bgImg} alt="modal-bg" />
      </BackgroundImageContainer>
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ModalTitle>Attention!</ModalTitle>
        <ModalInfo>
          Make sure you have at-least 0.5 Algos in your wallet before trying to
          connect.
        </ModalInfo>
        <ModalHashtag>#WelcomeToTheZone</ModalHashtag>
      </Modal.Body>
    </ModalWrapper>
  );
};
