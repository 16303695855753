import algosdk from "algosdk";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
// import MyAlgoLogo from "../assets/myalgo.svg";
import PeraLogo from "../assets/pera.svg";
import { getAccountInformation } from "../services/algod";
import { walletType } from "../utils";

function WalletName({ logo, text, onClick }) {
  return (
    <div className="walletName" onClick={onClick}>
      <div>
        <img src={logo} alt="wallet" className="walletLogo" />
        <p>{text}</p>
      </div>
    </div>
  );
}

export default function WalletChooseModal({
  setState,
  peraWallet,
  myAlgoConnect,
  ...props
}) {
  const [selectedWallet, setSelectedWallet] = useState(undefined);

  useEffect(() => {
    localStorage.clear();
  }, []);

  useEffect(() => {
    const connect = async () => {
      if (selectedWallet === walletType.connectWallet) {
        try {
          const accounts = await peraWallet.connect();
          peraWallet.connector?.on("disconnect", handleDisconnectWalletClick);

          const accountInfo = await getAccountInformation(accounts[0]);
          setState({
            walletAlgos: algosdk.microalgosToAlgos(accountInfo.amount),
            walletAddress: accounts[0],
            showWalletChooseModal: false,
            walletConnected: true,
            walletType: walletType.connectWallet,
          });

          localStorage.setItem("walletType", walletType.connectWallet);
        } catch (err) {
          if (err?.data?.type === "CONNECT_MODAL_CLOSED") {
            console.log("Closed Modal!");
          }
        }
      } else {
        const settings = {
          shouldSelectOneAccount: true,
          openManager: true,
        };

        const accounts = await myAlgoConnect.connect(settings);
        const accountInfo = await getAccountInformation(accounts[0].address);
        setState({
          walletAlgos: algosdk.microalgosToAlgos(accountInfo.amount),
          walletAddress: accounts[0].address,
          showWalletChooseModal: false,
          walletConnected: true,
          walletType: walletType.myalgo,
        });
        localStorage.setItem("walletType", walletType.myalgo);
      }
      // Old Code
      // try {
      //   const [account, balance] = await connectToWallet(selectedWallet);

      // setState({
      //   account: account,
      //   walletAlgos: balance,
      //   walletAddress: account.networkAccount.addr,
      //   showWalletChooseModal: false,
      //   walletConnected: true,
      //   walletType: walletType.myalgo,
      // });
      // } catch (err) {
      //   console.log(err);
      //   toast.error("Cannot connect to wallet");
      // }
    };

    if (selectedWallet) connect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWallet]);

  function handleDisconnectWalletClick() {
    peraWallet.disconnect();
    localStorage.clear();
  }
  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        contentClassName="walletChooseModal"
      >
        <h2 style={{ margin: "10px auto" }}>Select wallet</h2>
        <Modal.Body className="walletChooseModal__body">
          {/* <WalletName
            onClick={() => setSelectedWallet(walletType.myalgo)}
            logo={MyAlgoLogo}
            text="MyAlgo wallet"
          /> */}
          <WalletName
            onClick={() => setSelectedWallet(walletType.connectWallet)}
            logo={PeraLogo}
            text="Pera wallet"
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
